import { Container, Row, Col } from "react-bootstrap";

export const Aboutme = () => {
  return (
    <section className="about-me header-title" id="aboutme">
      <Container>
        <Row className="justify-content-md-center">
          <Col className="about-me-col" xs lg="12">
            <h2>About Me</h2>
            {/* <p>
              Hi! I'm Sheleen, a Web developer and Software Engineer in Bay Area
              California.
            </p>
            <p></p>
            <p>
              I was a Software Engineer in Canon Information Technologies
              Philippines, Inc., where I created a Document Writer module for
              the software of a printer product using C programming language. My
              experience working in a group of people with different styles and
              behaviors has taught and inspired me of different perspectives and
              the possibility of integrating each other's work successfully
              through cooperation and collaboration.
            </p>
            <p>
              During my internship in UP ITDC in University of the Philippines
              Diliman, I gained hands-on experience coding websites and
              applications in which I also gained valuable teamwork and
              communication skills. One of my projects was developing a
              web-based application project for online registration for courses
              called “My Eup Training and Events On-Line Registration (METEOR)”,
              using PHP. My team and I developed additional features such as
              giving the admin access to upload their signature to generate a
              Certificate for the participant, displaying the available dates
              for each course for the participant, listing out and showing who
              has enrolled for each course with it s corresponding dates and
              duration, and checking if the admin has already validated the
              participant's enrollment request. Those features were used by ITDC
              to easily track participants who wanted to enroll in different
              courses offered by the UP ITDC.
            </p>
            <p>So What happened?</p>
            <p>I moved out of my home country.</p>
            <p>
              I took time away from my professional career due to cultural
              adjustment and trying to gain security after relocating. Since
              everything in my life was a blur, I have decided to take the
              chance to work in a clinical laboratory as recommended by a
              friend. I stayed in the company for the past 5 years because I
              have learned a lot and enjoyed the work that I was doing. I was
              able to build self confidence because I wasn’t only doing
              laboratory work, I was also interacting and conversing with
              doctors and patients to resolve issues regarding their testing. I
              was also doing data entry for each patient and did most of the
              computer tasks quickly and efficiently.
            </p>
            <p>
              It came to a point where I have finally wanted to get out of my
              comfort zone. The saying “if something doesn't scare you, then
              you're probably not dreaming big enough” hit me big time and I
              have decided to get back at it once again. I started studying
              HTML, CSS and Javascript as refreshment; and REACT recently.
            </p>
            <p>
              I'm now ready to gain additional skills and knowledge in this
              field. The fusion of my experience, patience, dedication and
              perseverance along with the knowledge and skills that I have
              learned and will learn will really help me grow in this career. I
              also think that my natural ability to listen and learn by getting
              involved in an intelligent and considerate discussion resolving
              issues will truly help me in this field. I also enjoy working with
              people and learning from them.
            </p>
            <p>
              I am someone who is highly focused and attentive to detail and I
              thrive on developing software and applications that surpass end
              users' expectations.
            </p>
            <p>
              I'm excited to move forward and make a leap to leverage my unique
              range of skills and commitment with the right company.
            </p>
            <p>Wish me luck! 😀 </p> */}
            <p>
              I am a Software Engineer currently based in the Bay Area,
              California, and hold a Bachelor's degree in Computer Science from
              the University of the Philippines, offering a unique blend of
              academic knowledge and hands-on experience.
            </p>

            <p>
              Upon migrating to the United States, I navigated a period of
              cultural adjustment that led me to temporarily shift my focus to
              the healthcare sector, where I thrived in a clinical laboratory
              for the past five years.
            </p>

            <p>
              During this journey, I seized the opportunity to further enhance
              my skill set by working as an intern at HotelCheckins Inc. In this
              role, I successfully migrated the entire code base to Next.js,
              significantly improving the website's performance and
              optimization. I also played a pivotal role in ensuring mobile
              responsiveness and introduced new features, including policy
              management tailored to user preferences. Additionally, I took on
              the challenge of developing a mobile app for the website,
              showcasing my versatility in both web and mobile development.
            </p>

            <p>
              This internship experience not only allowed me to contribute to
              the evolution of the company's technology stack but also provided
              valuable insights into effective code management, performance
              enhancement, and feature development. I am well-versed in version
              control using GitHub and have experience deploying actual websites
              to servers for staging, leveraging platforms such as Vercel,
              Render, and PythonAnywhere.
            </p>

            <p>
              I am excited to leverage this diverse background, combined with my
              recent studies in HTML, CSS, Javascript, React, Next.js, and React
              Native Expo, to make impactful contributions in the ever-evolving
              field of software development. I am enthusiastic about the
              prospect of bringing my skills and experiences to your team and
              contributing to innovative projects.
            </p>

            <p>Wish me luck! 😀</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
